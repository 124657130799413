import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import "react-calendar/dist/Calendar.css";
import { Theme } from "./components/atoms/theme";
import { Toast } from "./components/molecules/Toast";
import { store } from "./redux/store";
import RouteNavigator from "./RouteNavigator";

const GlobalStyle = createGlobalStyle``;

Sentry.init({
  dsn: "https://548e880980f04fa532fd3b0870b00bff@o1309470.ingest.us.sentry.io/4508931781623808",
});

function App() {
  return (
    <Auth0Provider
      domain="login.rayna-app.com"
      scope="openid profile email"
      clientId="jjKa0N450DgJNiC9bnVogdAS08HZGBOy"
      redirectUri={window.location.origin}
    >
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <RouteNavigator />
          <Toast />
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
